$main-color: #005ca6;

@media only screen and (min-width: 1200px) {
  .form-container-mobile {
    display: none;
  }
  .form-container {
    font-family: "Helvetica Neue";
    margin-left: auto;
    margin-right: auto;
    margin-top: 450px;
    width: 85%;
    padding-left: 150px;
    padding-right: 150px;
    z-index: 33;
    background-color: white;
    box-shadow: 0px 50px 100px 0px rgba(0, 0, 0, 0.05);

    .form-header {
      text-align: center;
      position: relative;

      .logo {
        margin-bottom: 75px;

        img {
          height: 150px;
          margin-top: -75px;
          filter: drop-shadow(8px 0px 0 white) drop-shadow(0px -8px 0 white) drop-shadow(-8px 0px 0 white);
        }
      }

      .description_form {
        font-size: 25px;
        margin-bottom: 75px;
        padding: 0px 115px;
        text-align: center;
        width: 100%;
        height: auto;
      }

      .title {
        font-size: 76px;
        line-height: 78px;
        margin-bottom: 100px;
        text-transform: uppercase;
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 60px;
    }

    .years {
      justify-content: flex-start;
      padding-left: 25px;
      gap: 40px;
      font-size: 16px;
      form {
        display: contents;
        label {
          input[type="radio"] {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .submit-button {
    font-family: "Helvetica Neue";
    border: none;
    justify-content: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 30px;
    width: 225px;
    height: 80px;
    cursor: pointer;
  }

  .cancel-button {
    background-color: #f2f2f2;
    margin-right: 20px;
    cursor: pointer;
  }

  .signature-container {
    margin-bottom: 95px;

    .signature-label {
      margin-bottom: 30px;
      text-align: center;
      font-size: 16px;
    }
    .signature-buttons {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .finished-signature-container {
    margin-bottom: 20px;
    border: solid 1px black;

    img {
      max-width: 100%;
    }
  }

  .submit-button-row {
    padding-bottom: 40px;

    .final-submit-button {
      width: 100%;
    }
  }
  .footer {
    height: 50px;
    font-family: "Helvetica Neue";
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }

  .name-input {
    width: 40%;
  }

  .street-input {
    width: 30%;
  }

  .address-input {
    width: 11%;
  }

  .city-input {
    width: 30%;
  }

  .initial-input {
    width: 15%;
  }

  // SUCCESS PAGE CONTAINER

  .success-page-container {
    font-family: "Helvetica Neue";
    margin-left: auto;
    margin-right: auto;
    margin-top: 450px;
    width: 1500px;
    z-index: 33;
    background-color: white;
    box-shadow: 0px 50px 100px 0px rgba(0, 0, 0, 0.05);
    padding-top: 150px;

    .row-1 {
      font-size: 76px;
      text-align: center;
      margin-bottom: 75px;
    }

    .row-2 {
      font-size: 35px;
      text-align: center;
      margin-bottom: 75px;
    }

    .download-button-row {
      display: flex;
      justify-content: center;
      padding-bottom: 120px;
    }
  }

  .custom-dropdown-container {
    width: 30%;
    position: relative;
    .custom-dropdown-closed {
      font-family: "Helvetica Neue";
      height: 80px;
      width: 100%;
      padding-left: 25px;
      padding-bottom: 25px;
      padding-right: 30px;
      font-size: 16px;
      border-bottom: solid 1px #e6e6e6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 41.27px;
      cursor: pointer;

      img {
        height: 12px;
      }
    }

    .custom-dropdown-opened {
      font-family: "Helvetica Neue";
      width: 100%;
      padding-left: 25px;
      padding-bottom: 25px;
      padding-right: 30px;
      padding-top: 20px;
      font-size: 16px;
      position: absolute;
      z-index: 333;
      top: -60px;
      max-height: 350px;
      overflow-y: auto;

      .custom-dropdown-item {
        line-height: 41.27px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        img {
          height: 10px;
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
  }

  .error-row {
    text-align: center;
    margin-bottom: 10px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .form-container {
    display: none;
  }
  .form-container-mobile {
    font-family: "Helvetica Neue";
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 190px;
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100vw - 40px);
    z-index: 33;
    background-color: white;
    box-shadow: 0px 50px 100px 0px rgba(0, 0, 0, 0.05);

    .form-header {
      text-align: center;
      position: relative;

      .logo {
        margin-bottom: 30px;

        img {
          height: 75px;
          margin-top: -37px;
          filter: drop-shadow(4px 0px 0 white) drop-shadow(0px -4px 0 white) drop-shadow(-4px 0px 0 white);
        }
      }

      .description_form {
        margin-bottom: 30px;
      }

      .title {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 35px;
        text-transform: uppercase;
        text-align: center;
      }
    }

    .row {
      margin-bottom: 40px;

      .address-input {
        width: 22%;
      }

      .city-input {
        width: 47%;
      }

      .street-name-input {
        width: 70%;
      }

      .street-number-input {
        width: 25%;
      }
    }
    .years {
      display: flex;
      justify-content: flex-start;
      padding-left: 10px;
      gap: 40px;
      font-size: 14px;
      form {
        display: contents;
        label {
          input[type="radio"] {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .submit-button {
    font-family: "Helvetica Neue";
    border: none;
    color: white;
    justify-content: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    width: 225px;
    height: 50px;
    cursor: pointer;
  }

  .cancel-button {
    background-color: #f2f2f2;
    margin-right: 10px;
    cursor: pointer;
  }

  .signature-container {
    margin-bottom: 30px;

    .signature-label {
      margin-bottom: 20px;
      text-align: center;
      font-size: 14px;
    }
    .signature-buttons {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
  }

  .finished-signature-container {
    margin-bottom: 20px;
    border: solid 1px black;
    margin-left: 20px;
    margin-right: 20px;

    img {
      max-width: 100%;
    }
  }

  .submit-button-row {
    padding-bottom: 10px;

    .final-submit-button {
      width: 100%;
      cursor: pointer;
    }
  }

  .footer {
    height: 50px;
    font-family: "Helvetica Neue";
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }

  // SUCCESS PAGE CONTAINER

  .success-page-container {
    font-family: "Helvetica Neue";
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 190px;
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100vw - 40px);
    z-index: 33;
    background-color: white;
    box-shadow: 0px 50px 100px 0px rgba(0, 0, 0, 0.05);
    padding-top: 75px;

    .row-1 {
      font-size: 35px;
      line-height: 35px;
      text-align: center;
      margin-bottom: 20px;
    }

    .row-2 {
      font-size: 25px;
      line-height: 25px;
      text-align: center;
      margin-bottom: 40px;
    }

    .download-button-row {
      display: flex;
      justify-content: center;
      padding-bottom: 60px;
    }
  }

  .custom-dropdown-container {
    width: 30%;
    position: relative;
    .custom-dropdown-closed {
      font-family: "Helvetica Neue";
      height: 40px;
      width: 100%;
      padding-left: 10px;
      padding-bottom: 15px;
      padding-right: 30px;
      font-size: 14px;
      border-bottom: solid 1px #e6e6e6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 41.27px;
      cursor: pointer;

      img {
        height: 12px;
      }
    }

    .custom-dropdown-opened {
      font-family: "Helvetica Neue";
      width: 100%;
      padding-left: 25px;
      padding-bottom: 25px;
      padding-right: 30px;
      padding-top: 20px;
      font-size: 14px;
      color: white;
      position: absolute;
      z-index: 333;
      top: -60px;
      max-height: 350px;
      overflow-y: auto;

      .custom-dropdown-item {
        line-height: 34.27px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        img {
          height: 10px;
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
  }
  .error-row {
    text-align: center;
    margin-bottom: 10px;
    font-size: 12px;
  }
}

.label-radio {
  cursor: pointer;
  input[type="radio"] {
    cursor: pointer;
  }
}
