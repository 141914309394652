@media only screen and (min-width: 1200px) {
  .background-image-container {
    position: absolute;
    top: 0;
    z-index: -3333;
    height: 700px;
  }

  .background-image {
    max-height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .background-image-container {
    position: absolute;
    top: 0;
    z-index: -3333;
    height: 370px;
  }

  .background-image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.loader-container {
  text-align: center;
  margin-top: 50vh;
}
