$sub-color: #e6e6e6;

$main-color-poli: #005ca6;
$main-color-didi: #fbeb24;
$main-color-city: #023576;

@media only screen and (min-width: 1200px) {
  .group {
    position: relative;

    .form-input {
      background: none;
      background-color: white;
      // color: $main-color-poli;
      font-size: 16px;
      padding: 10px 10px 10px 25px;
      display: block;
      width: 100%;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $sub-color;
      margin-top: 25px;
      font-family: "Helvetica Neue" !important;

      &:focus ~ .form-input-label {
        padding-bottom: 10px;
        font-size: 12px;
        color: #666666 !important;
        width: 100%;
        // background-color: $main-color-poli;
      }

      &:focus {
        outline: none;
        background-color: $sub-color;
        // color: white;
      }
    }

    .form-input-didi {
      background: none;
      background-color: white;
      color: black;
      font-size: 16px;
      padding: 10px 10px 10px 25px;
      display: block;
      width: 100%;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $sub-color;
      margin-top: 25px;
      font-family: "Helvetica Neue" !important;

      &:focus ~ .form-input-label {
        padding-bottom: 10px;
        font-size: 12px;
        color: $main-color-didi !important;
        width: 100%;
        background-color: $main-color-didi;
      }

      &:focus {
        outline: none;
        background-color: $main-color-didi;
        color: black;
      }
    }

    .form-input-city {
      background: none;
      background-color: white;
      color: $main-color-city;
      font-size: 16px;
      padding: 10px 10px 10px 25px;
      display: block;
      width: 100%;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $sub-color;
      margin-top: 25px;
      font-family: "Helvetica Neue" !important;

      &:focus ~ .form-input-label {
        padding-bottom: 10px;
        font-size: 12px;
        color: $main-color-city !important;
        width: 100%;
        background-color: $main-color-city;
      }

      &:focus {
        outline: none;
        background-color: $main-color-city;
        color: white;
      }
    }

    input[type="password"] {
      letter-spacing: 0.3em;
    }

    .form-input-label {
      padding-left: 25px;
      font-size: 16px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      top: 0px;
      //transition: 300ms ease all;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .group {
    position: relative;

    .form-input {
      background: none;
      // color: #005ca6;
      background-color: white;
      font-size: 14px;
      padding: 10px 10px 10px 10px;
      display: block;
      width: 100%;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $sub-color;
      font-family: "Helvetica Neue" !important;

      &:focus ~ .form-input-label {
        font-size: 12px;
        color: #666666 !important;
        width: 100%;
        // background-color: $main-color-poli;
      }

      &:focus {
        outline: none;
        background-color: $sub-color;
        // color: white;
      }
    }

    .form-input-didi {
      background: none;
      color: black;
      background-color: white;
      font-size: 14px;
      padding: 10px 10px 10px 10px;
      display: block;
      width: 100%;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $sub-color;
      font-family: "Helvetica Neue" !important;

      &:focus ~ .form-input-label {
        font-size: 12px;
        color: $main-color-didi !important;
        width: 100%;
        background-color: $main-color-didi;
      }

      &:focus {
        outline: none;
        background-color: $main-color-didi;
        color: black;
      }
    }

    .form-input-city {
      background: none;
      color: $main-color-city;
      background-color: white;
      font-size: 14px;
      padding: 10px 10px 10px 10px;
      display: block;
      width: 100%;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $sub-color;
      font-family: "Helvetica Neue" !important;

      &:focus ~ .form-input-label {
        font-size: 12px;
        color: $main-color-city !important;
        width: 100%;
        background-color: $main-color-city;
      }

      &:focus {
        outline: none;
        background-color: $main-color-city;
        color: white;
      }
    }

    input[type="password"] {
      letter-spacing: 0.3em;
    }

    .form-input-label {
      padding-left: 10px;
      font-size: 14px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      top: -10px;
      //transition: 300ms ease all;
    }
  }
}
